import React from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const updateDescription = {
    label: 'Update:',
    points: [
        `Simplified inputs, including optional table names`,
        `Updated dashboard: Improved parsing, error indication, and streaming status`,
        `Added automatic refresh capability at set intervals`,
        `Performance Improvements for when many cells update`,
        `Bug fixes`,
    ],
  };

export default function UpdateScreen(props) {

  const handleClose = () => {
    props.handleUpdateClose()
  }

  const fontStyle = {style: {fontSize: 14}}

  return (
    <Dialog open={true} fullScreen >

        <div style={{marginTop: "50px", marginBottom: "0px"}}>
            <div className="welcome-image-container">
                <img src="./../../../assets/rtdip-horizontal-color.png"></img>
            </div>
        </div>  
    
    {/* Content */}
      <div style={{padding: "40px 40px", height: "100%"}}>

        <Typography sx={{marginBottom: "20px", fontSize: "20px"}}>
          {updateDescription["label"]}
        </Typography>        

        <div style={{backgroundColor: "aliceblue", borderRadius: "20px", padding: "30px"}}>
          <ul >
          {updateDescription["points"].map(point =>
            <li><Typography>{point}</Typography></li>
          )}
          </ul>

        </div>

        <div style={{paddingTop: "30px", display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Button 
            onClick={handleClose}
            variant="contained"
            color="secondary"
            sx={{
              width: "200px",
              backgroundColor: "blueviolet",
              "&:hover": {
                backgroundColor: "blueviolet",
                opacity: 0.9
              }
            }}>
            Continue
          </Button>
        </div>

      </div>
      
      


    </Dialog>
  );
}
