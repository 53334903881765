import React, {useState} from 'react'
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

function SearchTextField(props) {
    const [searchToggled, setSearchToggled] = useState(false)

    const handleSearchToggled = () => {
        setSearchToggled(!searchToggled)
    }

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
        
        <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
            <TextField  
                label={props.label}
                size="small" margin="dense"
                fullWidth={true}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: "none",
                    marginRight: "5px"
                }}
                inputProps={props.inputProps}
                InputLabelProps={props.InputLabelProps}
                value={props.value}
                onChange={(event) => {props.onChange(event)}}
                onFocus={(event) => {props.onFocus(event)}}
                onBlur={(event) => {props.onBlur(event)}}
            />

            <Button 
                type="button" 
                sx={{
                    margin: "7px 0",
                    minWidth: "0px",
                    width: "30px",
                    backgroundColor: "blueviolet",
                    '&:hover': {
                        backgroundColor: 'rgba(137, 43, 226, 0.822)',
                    },
                }} 
                variant="contained" 
                aria-label="search" 
                onClick={handleSearchToggled}
            >   
                {
                    searchToggled ? <CloseIcon /> :  (props.icon ? props.icon : <SearchIcon />)
                }
            
            </Button>
        </div>

        {
            searchToggled && React.Children.map(props.children, item => {
                return React.cloneElement(item, {
                    onClose: handleSearchToggled,
                    handlesubmit: handleSearchToggled
                });
            })
        }


        </div>
    );
}


export default SearchTextField;