import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Grid } from '@mui/material';
import ListButton from './ListButton';

function TableSelector(props) {
  const [catalog, setCatalog] = useState("")
  const [schema, setSchema] = useState("")
  const [table, setTable] = useState("")

  const [catalogOptions, setCatalogOptions] = useState([])
  const [schemaOptions, setSchemaOptions] = useState([])
  const [tableOptions, setTableOptions] = useState([])

  // On initial load, get the list of catalogs
  useEffect(() => {
    if(catalog.length == 0){
      const getCatalogs = async () => {
        let catalogs = await props.handleTableCatalogQuery({catalogName: "", schemaName: "", region: props.region})
        
        if(catalogs.length == 0){
          props.handleError("No catalogs found. There may be an issue with the connection to the database, please check your API url.")
        } else{
          // filter out unwanted catalogs
          const to_exclude = ["__databricks_internal", "data_monitoring", "system", "samples", "main"]
          catalogs = catalogs.filter(catalog => !to_exclude.includes(catalog))
        }
        setCatalogOptions(catalogs)
      }
      getCatalogs()
    }
  }, []);

  // When the catalog changes, get the list of schemas
  useEffect(() => {
    if(catalog.length > 0){ // Prevents the useEffect from running on initial load
      const getSchemas = async () => {
        setSchema("")
        let schemas = await props.handleTableCatalogQuery({catalogName: catalog, schemaName: "", region: props.region})
        if(schemas.length == 0){
          props.handleError("No schemas found for this catalog. Please check your access permissions.")
        } else{
          // filter out unwanted catalogs
          const to_exclude = ["default", "information_schema"]
          schemas = schemas.filter(schema => !to_exclude.includes(schema))
        }
        setSchemaOptions(schemas)
        setTableOptions([])
        setTable("")
      }
      getSchemas()
    }
  }, [catalog])

  // When the schema changes, get the list of tables
  useEffect(() => {
    if(schema.length > 0){ // Prevents the useEffect from running on initial load
      const getTables = async () => {
        setTable("")
        const tables = await props.handleTableCatalogQuery({catalogName: catalog, schemaName: schema, region: props.region})
        setTableOptions(tables)
      }
      getTables()
    }
  }, [schema])

  // When the table changes (i.e. user selected), update the table name in the parent component
  useEffect(() => {
    if(table.length > 0){ // Prevents the useEffect from running on initial load
      const tableName = `${catalog}.${schema}.${table}`
      props.updateTableName(tableName)
    }
  }, [table])

  // Define the event handlers for the ListButton components

  const handleCatalogSelection = (value) => {
    setCatalog(value)
  }

  const handleSchemaSelection = (value) => {
    setSchema(value)
  }

  const handleTableSelection = (value) => {
    setTable(value)
  }

  return (
    <Box sx={{ minWidth: "100%", position: "relative" }} >
      <Card variant="outlined" >
        <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center">
            <Grid item>
              <ListButton label="Catalog" value={catalog} enabled={catalogOptions.length > 0} options={catalogOptions} handleSelection={handleCatalogSelection}/>
            </Grid>
            <p><b>.</b></p>
            <Grid item>
              <ListButton label="Schema" value={schema} enabled={schemaOptions.length > 0} options={schemaOptions} handleSelection={handleSchemaSelection}/>
            </Grid>
            <p><b>.</b></p>
            <Grid item>
              <ListButton label="Table" value={table} enabled={tableOptions.length > 0} options={tableOptions} handleSelection={handleTableSelection}/>
            </Grid>
        </Grid>

      </Card>
    </Box>
  );
}

export default TableSelector;