import React, {useState, useEffect} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditIcon from '@mui/icons-material/Edit';
import LoopIcon from '@mui/icons-material/Loop';
import {findFormulas, clearCellContent, selectCell, refreshCell, parseFormulaString, addSheetIfNotPresent} from '../../utils/ExcelUtils';

const QUERY_TYPES = {
  "resample":                     {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval"], "advanced_params": ["aggMethod", "includeBadData", "includeDateColumn", "refreshInterval"]},
  "interpolate":                  {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval"], "advanced_params": ["aggMethod", "interpolationMethod", "includeBadData", "includeDateColumn", "refreshInterval"]},
  "interpolation_at_time":        {"required_params": ["apiUrl", "region", "tableName", "tagNames", "timestamps"], "advanced_params": ["windowLength", "includeBadData", "includeDateColumn", "refreshInterval"]},
  "raw":                          {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate"], "advanced_params": ["includeBadData", "refreshInterval"]},
  "time_weighted_average":        {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval"], "advanced_params": ["step", "includeBadData",  "includeDateColumn", "refreshInterval"]},
  "circular_average":             {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval", "lowerBound", "upperBound"], "advanced_params": ["includeBadData",  "includeDateColumn", "refreshInterval"]},
  "circular_standard_deviation":  {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "timeInterval", "lowerBound", "upperBound"], "advanced_params": ["includeBadData",  "includeDateColumn", "refreshInterval"]},
  "metadata":                     {"required_params": ["apiUrl", "region", "tableName", "tagNames"], "advanced_params": []},
  "latest":                       {"required_params": ["apiUrl", "region", "tableName", "tagNames"], "advanced_params": ["limit", "offset", "valueOnly", "refreshInterval"]},
  "summary":                      {"required_params": ["apiUrl", "region", "tableName", "tagNames", "startDate", "endDate", "summaryType"], "advanced_params": ["includeBadData", "refreshInterval"]}
}

const QUERY_NAME_MAPPING = {
  timeweightedavg:          "time_weighted_average",
  interpolate:              "interpolate",
  interpolationattime:      "interpolation_at_time",
  raw:                      "raw",
  circularavg:              "circular_average",
  circularstdev:            "circular_standard_deviation",
  metadata:                 "metadata",
  latest:                   "latest",
  resample:                 "resample",
  sql:                      "sql",
  summary:                  "summary"
};

const DEFAULT_PARAMS = {
  "aggMethod": "first",
  "includeBadData": false,
  "interpolationMethod": "forward_fill",
  "timestamps": [],
  "windowLength": 1,
  "step": true,
  "lowerBound": "",
  "upperBound": "",
  "outputCell": "Sheet1!A1",
  "includeDateColumn": false,
  "limit": 1,
  "offset": 0,
  "valueOnly": false,
  "region": "EMEA",
  "summaryType": "Avg"
}



function Dashboard(props) {
    const [loading, setLoading] = useState(false)

    useEffect(async () => {
        setLoading(true)

        // Get all formulas in the sheet in format {formulas: [{address: "A1", content: "=RTDIP.RAW()"}], sheetName: "Sheet1"}
        const foundFormulaData = await findFormulas()  

        // Try to parse the formulas 
        foundFormulaData["formulas"].forEach(item => {
          try {
            const paramsAndSettings = parseFormulaString(item["content"], foundFormulaData["sheetName"], QUERY_TYPES, QUERY_NAME_MAPPING)
            item["parsedParameters"] = paramsAndSettings["params"]
          } catch (e) {
            console.log(e)
          }
        })

        // Check if the formula has an error in its value on th sheet
        foundFormulaData["formulas"].forEach(item => {
          try{
            const formulaValue = item["value"]
            // if first characters are "Error:" or the Excel VALUE or CALC error, then show error
            if (formulaValue.slice(0, 6) == "Error:" || formulaValue == "#VALUE!" || formulaValue == "#CALC!") {
              item["error"] = true
            } else {
              item["error"] = false
            }
          } catch (e) {
            console.log(e)
          }
        })

        // check existing formulas, and ensure to keep those that are still valid (i.e. exact same formula)
        const newFormulas = []
        for (let i = 0; i < foundFormulaData["formulas"].length; i++) {
          const item = foundFormulaData["formulas"][i]
          // check if the formula is still the same
          const found = props.formulaData.formulas.find(formula => formula["address"] == item["address"])
          const identical = found && found["content"] == item["content"] && found["error"] == item["error"]
          if (found && identical) {
            newFormulas.push(found)
          } else {
            newFormulas.push(item)
          }
        }

        // set the state of the formulas
        props.setFormulaData({
          formulas: newFormulas,
          sheetName: foundFormulaData["sheetName"]
        })
        
        setLoading(false)
    }, [])

    const handleAccordianClick = async (e, cellName) => {
      selectCell(cellName)
    }

    const handleDelete = async (e, item) => {
      // delete the formula from the cell
      await clearCellContent(item["address"])

      // remove the formula from the state
      props.setFormulaData({
        ...props.formulaData,
        formulas: props.formulaData.formulas.filter(formula => formula["address"] != item["address"])
      })

    }

    const handleEdit = async (e, item) => {
      
      try{
        const address = addSheetIfNotPresent(item["address"], props.formulaData["sheetName"])
        // replace all null/undefined values with default values
        for (const key in item["parsedParameters"]) {
          if(item["parsedParameters"][key] == null) {
            item["parsedParameters"][key] = DEFAULT_PARAMS[key]
            }
        }

        props.handleEdit(address, item["parsedParameters"])
      } catch (e) {
        console.log(e)
        props.handleError("This formula cannot be edited with the form.Please edit manually in the formula bar.")
      }
      
    }

    const handleRefresh = async (e, item) => {
      refreshCell(item["address"], item["content"])
    }


  return (
    <div className='dashboard'>
      
      {props.formulaData.formulas.length == 0 && 
        <div className="dashboard-hint">

          <div className="dashboard-try">
            <p>Try create a query with Auto Query or a raw SQL statement: </p>
            <p className="dashboard-try-query">SELECT * FROM rtdip.sensors.test_table_float LIMIT 10</p>
          </div>

          <p>All current uses of the RTDIP Add-in from this sheet will be shown here</p>

        </div>
      }
    
      {props.formulaData.formulas.map( (item, idx) => 
          <Accordion key={idx} onClick={(e) => handleAccordianClick(e, item["address"])}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className='dashboard-item'
            >
              <p className='dashboard-item-cell'>{item["address"]}</p>
              <p className='dashboard-item-formula'>{item["content"].match(/\.(\w+)\(/)[1]}</p>

              {item["error"] &&
                <div className={`dashboard-item-time`}>
                  <p>Error</p>
                </div>
              }

              {item["parsedParameters"] && item["parsedParameters"]["refreshInterval"] && !item["error"] &&
                <div className={`dashboard-item-time dashboard-pulse-button`}>
                  <p>Streaming</p>
                </div>
              }
            </AccordionSummary>
            <AccordionDetails className='dashboard-accordian-details'>
                  <IconButton  color="error" type="submit" onClick={(e) => handleDelete(e, item)}>
                      <DeleteIcon/>
                  </IconButton>
                  <IconButton  color="primary" type="submit" onClick={(e) => handleEdit(e, item)}>
                      <EditIcon />
                  </IconButton>
                  <IconButton  color="secondary" type="submit" onClick={(e) => handleRefresh(e, item)}>
                      <AutorenewIcon />
                  </IconButton>
            </AccordionDetails>
          </Accordion>
      )}

      {loading && 
        <div className='dashboard-loading'>
          <LoopIcon className='input-icon'/>
        </div>
      }
   
    </div>
  );
}

export default Dashboard;