import React, { useState, useEffect, useRef} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import ListIcon from '@mui/icons-material/List';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import SearchTextField from "../SearchTextField/SearchTextField";
import TableSelector from "../TableSelector/TableSelector";
import TagSearchDialog from "../TagSearchDialog/TagSearchDialog";
import {addClickHandler, removeClickHandler} from '../../utils/ExcelUtils'


function Form(props) {
    const [queryType, setQueryType] = useState("raw")
    const [tableName, setTableName] = useState("")
    const [tagNames, setTagNames] = useState([])
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [timeInterval, setTimeInterval] = useState("");
    const [aggMethod, setAggMethod] = useState("first");
    const [includeBadData, setIncludeBadData] = useState(false);
    const [interpolationMethod, setInterpolationMethod] = useState("forward_fill");
    const [timestamps, setTimestamps] = useState([]);
    const [windowLength, setWindowLength] = useState(1);
    const [step, setStep] = useState(true);
    const [lowerBound, setLowerBound] = useState("");
    const [upperBound, setUpperBound] = useState("");    
    const [outputCell, setOutputCell] = useState("Sheet1!A1");
    const [includeDateColumn, setIncludeDateColumn] = useState(false)
    const [limit, setLimit] = useState(1)
    const [offset, setOffset] = useState(0)
    const [valueOnly, setValueOnly] = useState(false)
    const [region, setRegion] = useState("EMEA")
    const [summaryType, setSummaryType] = useState("Avg")
    const [refreshInterval, setRefreshInterval] = useState(null)
    const [activeInput, setActiveInput] = useState(null)
    const [statefulEventResult, setStatefulEventResult] = useState(null)
    const [selectedCell, setSelectedCell] = useState(null)

    useEffect(() => {

        if (Object.keys(props.formData).length > 0) {

            const required_params = parameterObject[props.formData["query_type"]]["required_params"]
            const advanced_params = parameterObject[props.formData["query_type"]]["advanced_params"]

            let fieldsToPopulate = required_params.concat(advanced_params)
            fieldsToPopulate.map( field => {
                fieldObject[field].setter(props.formData[field])
            })
            setQueryType(props.formData["query_type"])
            setOutputCell(props.formData["outputCell"])
        }
      }, [props.formData]);


    useEffect(async () => { 
        // Add click handler to excel on mount so that we can get the selected cell
        let callback = (cellAddress) => {
            setSelectedCell(cellAddress)
        }

        let eventResult = await addClickHandler(callback)
        setStatefulEventResult(eventResult)

        // Define cleanup function for component unmount
        const cleanup = async () => {
            if(statefulEventResult != null){
                await removeClickHandler(statefulEventResult)
            }
            setStatefulEventResult(null)
        }

        return cleanup
    }, [])


    // Add body click listener to remove active input setting
        const handleBodyClick = (event) => {
            if(event.target.classList.contains("MuiInputBase-input") || event.target.classList.contains("MuiTextField-root")){
                return
            }
            setActiveInput(null)
        };
    
      useEffect(() => {
        
        document.body.addEventListener('click', handleBodyClick);
    
        // Cleanup function to remove the event listener
        return () => {
          document.body.removeEventListener('click', handleBodyClick);
        };
      }, []);


    useEffect(async () => { 
        // If a cell is selected and an input is active, set the value of the input to the selected cell
        if(selectedCell != null && activeInput != null){

            let setter = fieldObject[activeInput]["setter"]
            if(activeInput == "tagNames"|| activeInput == "timestamps") {
                handleInputArrayChange({target: {value: selectedCell} }, setter)
            } else {
                setter(selectedCell)
            }
        }
        setActiveInput(null)
    }, [selectedCell])


    const handleSubmit = (e) => {
        e.preventDefault();
        
        // clean up any active input
        setActiveInput(null)

        let paramsToSend = {"mode": "guided", "query_type": queryType, "outputCell": outputCell}

        // Add all required parameters to the paramsToSend object
        parameterObject[queryType]["required_params"].map(param => {
            paramsToSend[param] = fieldObject[param].value
        })

        // Add all advanced parameters to the paramsToSend object
        parameterObject[queryType]["advanced_params"].map(param => {
            paramsToSend[param] = fieldObject[param].value
        })

        // send the parameter values along with the parameter names as per query type
        props.handleClick(paramsToSend, parameterObject[queryType]);
    }
 
    // Handle input focus
    const handleInputClick = async (param) => {
        setActiveInput(param)
    };
    
    const handleSelectorClick = () => {
        // clean up any active input in case they are no longer rendered
        setActiveInput(null)
    }

    const handleBlur = async (param) => {}

    const handleQueryChange = (event) => {
        setQueryType(event.target.value);
      };

    const handleRegionChange = (event) => {
        setRegion(event.target.value);
    };

    const handleBadDataChange = (event) => {
        setIncludeBadData(event.target.value);
    };

    const handleAggMethodChange = (event) => {
        setAggMethod(event.target.value);
    };

    const handleInterpolationMethodChange = (event) => {
        setInterpolationMethod(event.target.value);
    };

    const handleStepChange = (event) => {
        setStep(event.target.value);
    };


    const handleIncludeDateColChange = (event) => {
        setIncludeDateColumn(event.target.value);
    };

    const handleValueOnlyChange = (event) => {
        setValueOnly(event.target.value);
    };

    const handleSummaryTypeChange = (event) => {
        setSummaryType(event.target.value);
    };

    const handleInputArrayChange = (event, setter) => {
        const inputValue = event.target.value;
        const newArray = inputValue.split(",").map(item => item.trim())
        setter(newArray)
    }

    const handleTagSave = (tags) => {
        setTagNames(tags)
    }

    const parameterObject = {
        "resample":                     {"required_params": ["region", "tableName", "tagNames", "startDate", "endDate", "timeInterval"], "advanced_params": ["aggMethod", "includeBadData", "includeDateColumn", "refreshInterval"]},
        "interpolate":                  {"required_params": ["region", "tableName", "tagNames", "startDate", "endDate", "timeInterval"], "advanced_params": ["aggMethod", "interpolationMethod", "includeBadData", "includeDateColumn", "refreshInterval"]},
        "interpolation_at_time":        {"required_params": ["region", "tableName", "tagNames", "timestamps"], "advanced_params": ["windowLength", "includeBadData", "includeDateColumn", "refreshInterval"]},
        "raw":                          {"required_params": ["region", "tableName", "tagNames", "startDate", "endDate"], "advanced_params": ["includeBadData", "refreshInterval"]},
        "time_weighted_average":        {"required_params": ["region", "tableName", "tagNames", "startDate", "endDate", "timeInterval"], "advanced_params": ["step", "includeBadData",  "includeDateColumn", "refreshInterval"]},
        "circular_average":             {"required_params": ["region", "tableName", "tagNames", "startDate", "endDate", "timeInterval", "lowerBound", "upperBound"], "advanced_params": ["includeBadData",  "includeDateColumn", "refreshInterval"]},
        "circular_standard_deviation":  {"required_params": ["region", "tableName", "tagNames", "startDate", "endDate", "timeInterval", "lowerBound", "upperBound"], "advanced_params": ["includeBadData",  "includeDateColumn", "refreshInterval"]},
        "metadata":                     {"required_params": ["region", "tableName", "tagNames"], "advanced_params": []},
        "latest":                       {"required_params": ["region", "tableName", "tagNames"], "advanced_params": ["limit", "offset", "valueOnly", "refreshInterval"]},
        "summary":                      {"required_params": ["region", "tableName", "tagNames", "startDate", "endDate", "summaryType"], "advanced_params": ["includeBadData", "refreshInterval"]}
    }
    
    let fieldObject = {
        "tableName":           {label: "Table name (optional)", value: tableName, setter: setTableName, tooltip: "The table name should be in the format 'businessunit.catalog.asset_datasecuritylevel_datatype'. Required for confidential tables."},
        "tagNames":            {label: "Tag Names", value: tagNames,  setter: setTagNames},
        "startDate":           {label: "Start Date", value: startDate, setter: setStartDate, tooltip: "Accepted formats include: YYYY-MM-DD HH:mm:ss, DD/MM/YYYY HH:mm:ss, MM-DD-YYYY HH:mm, Excel numerical or PI style  (e.g. *-1d). Hours and minutes are optional."},
        "endDate":             {label: "End Date", value: endDate, setter: setEndDate,  tooltip: "Accepted formats include: YYYY-MM-DD HH:mm:ss, DD/MM/YYYY HH:mm:ss, MM-DD-YYYY HH:mm and Excel numerical or PI style  (e.g. *-1d). Hours and minutes are optional."},
        "timeInterval":        {label: "Time Interval", value: timeInterval, setter: setTimeInterval, tooltip: "Accepted formats include: m, h, d e.g. 15m, 1h, 7d"},
        "aggMethod":           {label: "Aggregation Method", value: aggMethod, setter: setAggMethod},
        "includeBadData":      {label: "Include Bad Data", value: includeBadData, setter: setIncludeBadData},
        "interpolationMethod": {label: "Interpolation Method", value: interpolationMethod, setter: setInterpolationMethod},
        "timestamps":          {label: "Timestamps", value: timestamps, setter: setTimestamps},
        "windowLength":        {label: "Window Length", value: windowLength, setter: setWindowLength},
        "step":                {label: "Step", value: step, setter: setStep},
        "lowerBound":          {label: "Lower Bound", value: lowerBound, setter: setLowerBound},
        "upperBound":          {label: "Upper Bound", value: upperBound, setter: setUpperBound},
        "includeDateColumn":   {label: "Include Date Column", value: includeDateColumn, setter: setIncludeDateColumn},
        "limit":               {label: "Limit", value: limit, setter: setLimit},
        "offset":              {label: "Offset", value: offset, setter: setOffset},
        "valueOnly":           {label: "Value Only", value: valueOnly, setter: setValueOnly},
        "region":              {label: "Region", value: region, setter: setRegion},
        "outputCell":          {label: "Output Cell", value: outputCell, setter: setOutputCell, tooltip: "This must include the sheet name"},
        "summaryType":         {label: "Summary Type", value: summaryType, setter: setSummaryType},
        "refreshInterval":     {label: "Refresh Interval (s)", value: refreshInterval, setter: setRefreshInterval}
    }

    const fontStyle = {style: {fontSize: 14}}

    return (
        <form onSubmit={handleSubmit} data-testid="guided-query-form">
    
            <div className="input-container">

                <FormControl fullWidth size="small" margin="dense" key="query-select">
                    <InputLabel id="query-select-label">Type</InputLabel>
                    <Select
                    labelId="query-select-label"
                    data-testid="query-select"
                    id="query-select"
                    value={queryType}
                    label="Type"
                    onChange={handleQueryChange}
                    onClick={handleSelectorClick}
                    sx={{fontSize: 14}}
                    MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Set your desired max height here
                            overflow: 'auto',
                          },
                          sx: {
                            '&::-webkit-scrollbar': {
                              width: '5px',
                              height: '5px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#dad7d7',
                              borderRadius: '10px',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: 'transparent',
                            },
                          },
                        },
                      }}
                    >
                        <MenuItem data-testid="query-select-raw" value={"raw"} sx={{fontSize: 14}}>Raw</MenuItem>
                        <MenuItem value={"resample"} sx={{fontSize: 14}}>Resample</MenuItem>
                        <MenuItem value={"interpolate"} sx={{fontSize: 14}}>Interpolate</MenuItem>
                        <MenuItem value={"interpolation_at_time"} sx={{fontSize: 14}}>Interpolation at Time</MenuItem>
                        <MenuItem value={"time_weighted_average"} sx={{fontSize: 14}}>Time Weighted Average</MenuItem>
                        <MenuItem value={"circular_average"} sx={{fontSize: 14}}>Circular Average</MenuItem>
                        <MenuItem value={"circular_standard_deviation"} sx={{fontSize: 14}}>Circular Standard Deviation</MenuItem>
                        <MenuItem value={"metadata"} sx={{fontSize: 14}}>Metadata</MenuItem>
                        <MenuItem value={"latest"} sx={{fontSize: 14}}>Latest</MenuItem>
                        <MenuItem value={"summary"} sx={{fontSize: 14}}>Summary</MenuItem>
                    </Select>
                </FormControl>


                {parameterObject[queryType]["required_params"].map(param => {
                    if(param == "tableName") {
                        return(
                                <SearchTextField 
                                    key={fieldObject[param]["label"]}
                                    label={<Tooltip arrow placement="top" title={fieldObject[param]["tooltip"]}>{fieldObject[param]["label"]}</Tooltip>}
                                    inputProps={fontStyle}
                                    InputLabelProps={fontStyle}
                                    icon={<ListIcon />}
                                    value={fieldObject[param]["value"]}
                                    onChange={(event) => {fieldObject[param]["setter"](event.target.value)}}
                                    onFocus={(e) => handleInputClick(param)}
                                    onBlur={(e) => handleBlur(param)}
                                >
                                    <TableSelector 
                                        handleTableCatalogQuery={props.handleTableCatalogQuery}
                                        updateTableName={setTableName}
                                        handleError={props.handleError}
                                        region={region}
                                    />
                                </SearchTextField>
                        )

                    } else if(param == "tagNames"){
                        return (
                            <SearchTextField 
                                key={fieldObject[param]["label"]}
                                label={fieldObject[param]["label"]}
                                inputProps={fontStyle}
                                InputLabelProps={fontStyle}
                                icon={<ManageSearchIcon />}
                                value={fieldObject[param]["value"].join(',')}
                                onChange={(event) => handleInputArrayChange(event, fieldObject[param]["setter"])}
                                onFocus={(e) => handleInputClick(param)}
                                onBlur={(e) => handleBlur(param)}
                            >
                                <TagSearchDialog
                                    open={true}
                                    currentTags={fieldObject[param]["value"]}
                                    handleSave={handleTagSave}
                                    handleTagSearchQuery={props.handleTagSearchQuery}
                                    handleTableCatalogQuery={props.handleTableCatalogQuery}
                                    handleError={props.handleError}
                                    region={region}
                                    initialTableName={tableName}
                                />
                            </SearchTextField>
                        )
                    } 
                    else if(param == "timestamps"){
                        return (
                            <TextField 
                                key={fieldObject[param]["label"]}
                                label="Timestamps"
                                size="small"
                                margin="dense"
                                fullWidth={true}
                                inputProps={fontStyle}
                                InputLabelProps={fontStyle}
                                value={timestamps}
                                onFocus={(e) => handleInputClick(param)}
                                onChange={(event) => handleInputArrayChange(event, fieldObject[param]["setter"])}
                                onBlur={(e) => handleBlur(param)}
                            />
                        )
                    } else if(param == "region") {
                        return (
                        <FormControl fullWidth size="small" margin="dense" key={fieldObject[param]["label"]}>
                            <InputLabel id="region-select-label">Region</InputLabel>
                            <Select
                                labelId="region-select-label"
                                id="region-select"
                                value={region}
                                label="Region"
                                onChange={handleRegionChange}
                                sx={{fontSize: 14}}
                            >
                                <MenuItem value={"EMEA"}        sx={{fontSize: 14}}>EMEA</MenuItem>
                                <MenuItem value={"AsiaPac"}     sx={{fontSize: 14}}>AsiaPac</MenuItem>
                                <MenuItem value={"Americas"}    sx={{fontSize: 14}}>Americas</MenuItem>
                            </Select>
                        </FormControl>)
                    }else if(param == "summaryType") {
                        return (
                        <FormControl fullWidth size="small" margin="dense" key={fieldObject[param]["label"]}>
                            <InputLabel id="summaryType-select-label">Summary Type</InputLabel>
                            <Select
                                labelId="summaryType-select-label"
                                id="summaryType-select"
                                value={summaryType}
                                label="Summary Type"
                                onChange={handleSummaryTypeChange}
                                sx={{fontSize: 14}}
                            >
                                <MenuItem value={"Avg"}  sx={{fontSize: 14}}>Avg</MenuItem>
                                <MenuItem value={"Min"}  sx={{fontSize: 14}}>Min</MenuItem>
                                <MenuItem value={"Max"}  sx={{fontSize: 14}}>Max</MenuItem>
                                <MenuItem value={"Sum"}  sx={{fontSize: 14}}>Sum</MenuItem>
                                <MenuItem value={"Count"}  sx={{fontSize: 14}}>Count</MenuItem>
                                <MenuItem value={"StDev"}  sx={{fontSize: 14}}>StDev</MenuItem>
                                <MenuItem value={"Var"}  sx={{fontSize: 14}}>Variance</MenuItem>
                            </Select>
                        </FormControl>)
                    } else {
                        return (
                            <div style={{display: "flex", flexDiirection: "row", alignContent: "center", width: "100%", position: "relative"}}>
                            <TextField  
                                key={fieldObject[param]["label"]}
                                label={fieldObject[param]["label"]}
                                size="small" 
                                margin="dense"
                                fullWidth={true}
                                inputProps={fontStyle}
                                InputLabelProps={fontStyle}
                                value={fieldObject[param]["value"]}
                                onChange={(event) => {fieldObject[param]["setter"](event.target.value)}}
                                onBlur={(e) => handleBlur(param)}
                                onFocus={(e) => handleInputClick(param)}
                                focused={activeInput == param}
                            />
                            {fieldObject[param]["tooltip"] &&
                                <Tooltip arrow placement="top" title={fieldObject[param]["tooltip"]} >
                                    <p style={{position: 'absolute', right: 8}}>
                                        <InfoIcon sx={{opacity: 0.7, color: "lightgray", fontSize: "20px"}}/>
                                    </p>
                                </Tooltip>
                            }
                            </div>
                        )
                    }
                })}

                <TextField 
                    label="Output cell"
                    size="small"
                    margin="dense"
                    fullWidth={true}
                    inputProps={fontStyle}
                    InputLabelProps={fontStyle}
                    value={outputCell}
                    onFocus={(e) => handleInputClick("outputCell")}
                    onBlur={(e) => handleBlur("outputCell")}
                    onChange={(event) => setOutputCell(event.target.value)}
                />
            </div>

            <div className="button-container">
                <Button className='button-form-submit' endDecorator={<KeyboardArrowRight />} color="info" type="submit">
                Run
                </Button>
            </div>

            {parameterObject[queryType]["advanced_params"].length > 0 && 
                    <Accordion className='form-advanced-params-accordian'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Advanced Parameters
                        </AccordionSummary>

                        <AccordionDetails className='form-advanced-params-accordian-details'>
                    {parameterObject[queryType]["advanced_params"].map(param => {

                    if(param == "aggMethod") {
                        return (
                        <FormControl fullWidth size="small" margin="dense" key={fieldObject[param]["label"]}>
                            <InputLabel id="aggMethod-select-label">Aggregation method</InputLabel>
                            <Select
                                labelId="aggMethod-select-label"
                                id="aggMethod-select"
                                value={aggMethod}
                                label="Aggregation Method"
                                onChange={handleAggMethodChange}
                                sx={{fontSize: 14}}
                            >
                                <MenuItem value={"first"} sx={{fontSize: 14}}>First</MenuItem>
                                <MenuItem value={"last"}  sx={{fontSize: 14}}>Last</MenuItem>
                                <MenuItem value={"avg"}   sx={{fontSize: 14}}>Avg</MenuItem>
                                <MenuItem value={"min"}   sx={{fontSize: 14}}>Min</MenuItem>
                                <MenuItem value={"max"}   sx={{fontSize: 14}}>Max</MenuItem>
                            </Select>
                        </FormControl>)
                    } else if(param == "includeBadData") {
                        return (
                        <FormControl fullWidth size="small" margin="dense" key={fieldObject[param]["label"]}>
                            <InputLabel id="includeBadData-select-label">Include Bad Data</InputLabel>
                            <Select
                                labelId="includeBadData-select-label"
                                id="includeBadData-select"
                                value={includeBadData}
                                label="Include Bad Data"
                                onChange={handleBadDataChange}
                                sx={{fontSize: 14}}
                            >
                                <MenuItem value={true}  sx={{fontSize: 14}}>True</MenuItem>
                                <MenuItem value={false}  sx={{fontSize: 14}}>False</MenuItem>
                            </Select>
                        </FormControl>)
                    } else if(param == "interpolationMethod") {
                        return (
                        <FormControl fullWidth size="small" margin="dense" key={fieldObject[param]["label"]}>
                            <InputLabel id="interpolationMethod-select-label">Interpolation Method</InputLabel>
                            <Select
                                labelId="interpolationMethod-select-label"
                                id="interpolationMethod-select"
                                value={interpolationMethod}
                                label="Interpolation Method"
                                onChange={handleInterpolationMethodChange}
                                sx={{fontSize: 14}}
                            >
                                <MenuItem value={"forward_fill"}   sx={{fontSize: 14}}>Forward Fill</MenuItem>
                                <MenuItem value={"backward_fill"}  sx={{fontSize: 14}}>Backward Fill</MenuItem>
                                <MenuItem value={"linear"}         sx={{fontSize: 14}}>Linear</MenuItem>
                            </Select>
                        </FormControl>)
                    } else if(param == "step") {
                        return (
                        <FormControl fullWidth size="small" margin="dense" key={fieldObject[param]["label"]}>
                            <InputLabel id="step-select-label">Step</InputLabel>
                            <Select
                                labelId="step-select-label"
                                id="step-select"
                                value={step}
                                label="Step"
                                onChange={handleStepChange}
                                sx={{fontSize: 14}}
                            >
                                <MenuItem value={true}  sx={{fontSize: 14}}>True</MenuItem>
                                <MenuItem value={false}  sx={{fontSize: 14}}>False</MenuItem>
                            </Select>
                        </FormControl>)
                    } else if(param == "includeDateColumn") {
                        return (
                        <FormControl fullWidth size="small" margin="dense" key={fieldObject[param]["label"]}>
                            <InputLabel id="includeDateColumn-select-label">Include Date Column</InputLabel>
                            <Select
                                labelId="includeDateColumn-select-label"
                                id="includeDateColumn-select"
                                value={includeDateColumn}
                                label="Include Date Column"
                                onChange={handleIncludeDateColChange}
                                sx={{fontSize: 14}}
                            >
                                <MenuItem value={true}  sx={{fontSize: 14}}>True</MenuItem>
                                <MenuItem value={false}  sx={{fontSize: 14}}>False</MenuItem>
                            </Select>
                        </FormControl>)
                    } else if(param == "valueOnly") {
                        return (
                        <FormControl fullWidth size="small" margin="dense" key={fieldObject[param]["label"]}>
                            <InputLabel id="valueOnly-select-label">Value Only</InputLabel>
                            <Select
                                labelId="valueOnly-select-label"
                                id="valueOnly-select"
                                value={valueOnly}
                                label="Value Only"
                                onChange={handleValueOnlyChange}
                                sx={{fontSize: 14}}
                            >
                                <MenuItem value={true}  sx={{fontSize: 14}}>True</MenuItem>
                                <MenuItem value={false}  sx={{fontSize: 14}}>False</MenuItem>
                            </Select>
                        </FormControl>)
                    }  else {
                        return (
                            <div style={{display: "flex", flexDiirection: "row", alignContent: "center", width: "100%", position: "relative"}}>
                            <TextField  
                                key={fieldObject[param]["label"]}
                                label={fieldObject[param]["label"]}
                                size="small" 
                                margin="dense"
                                fullWidth={true}
                                inputProps={fontStyle}
                                InputLabelProps={fontStyle}
                                value={fieldObject[param]["value"]}
                                onChange={(event) => {fieldObject[param]["setter"](event.target.value)}}
                                onBlur={(e) => handleBlur(param)}
                                onFocus={(e) => handleInputClick(param)}
                                focused={activeInput == param}
                            />
                            {fieldObject[param]["tooltip"] &&
                                <Tooltip arrow placement="top" title={fieldObject[param]["tooltip"]} >
                                    <p style={{position: 'absolute', right: 8}}>
                                        <InfoIcon sx={{opacity: 0.7, color: "lightgray", fontSize: "20px"}}/>
                                    </p>
                                </Tooltip>
                            }
                            </div>
                        )
                    }
                })}
                        </AccordionDetails>

                    </Accordion>
                }

        </form>
    )
}


export default Form;