import React, { useState, useEffect} from 'react'
import axios from 'axios';
import Progress from "./Progress/Progress";
import Form from "./Form/Form.js"
import RawQueryForm from "./RawQueryForm/RawQueryForm.js";
import {getSettingsExcel, setSettingsExcel, addFormulaToCell } from "../utils/ExcelUtils.js";
import { getTablesAndCatalogs, searchTagNames } from "../../functions/functionUtils.js";
import Alert from '@mui/material/Alert';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SettingsDialog from "./SettingsDialog/SettingsDialog.js";
import ViewListIcon from '@mui/icons-material/ViewList';
import Dashboard from "./Dashboard/Dashboard"
import WelcomeScreen from "./WelcomeScreen/WelcomeScreen.js"
import UpdateScreen from "./UpdateScreen/UpdateScreen.js"
import "../taskpane.css"

/* global console, Excel, require */

let APP_VERSION_NUMBER = "1.2"

function App(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({occured: false, message: ""})
  const [switchAlignment, setSwitchAlignment] = useState("Guided")
  const [formData, setFormData] = useState({})
  const [sqlFormData, setSQLFormData] = useState({})

  // Structure of state: {formulas: [{address: "A1", content: "=RTDIP.timeweightedavg()"}, parsedParameters: {"param": ...}], sheetName: "Sheet1"}
  const [dashboardFormulaData, setDashboardFormulaData] = useState({formulas: [], sheetName: ""})

  const [seenWelcomeScreen, setSeenWelcomeScreen] = useState(() => {
    const storedValue = localStorage.getItem('seenWelcomeScreen');
    return storedValue && storedValue != "undefined" ? storedValue : false;
  });

  const [storedAppVersionNumber, setStoredAppVersionNumber] = useState(() => {
    const storedValue = localStorage.getItem('APP_VERSION_NUMBER');
    return storedValue && storedValue != "undefined" ? storedValue : "";
  });

  const [apiUrl, setApiUrl] = useState(() => {
    const storedValue = localStorage.getItem('apiUrl');
    return storedValue && storedValue != "undefined" ? storedValue : "";
  });

  const [hostname, setHostname] = useState(() => {
    const storedValue = localStorage.getItem('hostname');
    return storedValue && storedValue != "undefined" ? storedValue : "";
  });

  const [httpPath, setHttpPath] = useState(() => {
    const storedValue = localStorage.getItem('httpPath');
    return storedValue && storedValue != "undefined" ? storedValue : "";
  });

  const [includeHeaders, setIncludeHeaders] = useState(() => {
    const storedValue = localStorage.getItem('includeHeaders');
    return storedValue && storedValue != "undefined" ? storedValue : "true";
  });

  const [headerSettings, setHeaderSettings] = useState(() => {
    const storedValue = JSON.parse(localStorage.getItem('headerSettings'));
    return storedValue && storedValue != "undefined" ? storedValue : {backgroundColor: "blueviolet", textColor: "#ffffff"}
  });

  const [dateSettings, setDateSettings] = useState(() => {
    const storedValue = localStorage.getItem('dateSettings');
    return storedValue && storedValue != "undefined" ? storedValue : "user-timezone";
  });

  useEffect(() => {
    handleSettingsChange(hostname, httpPath, apiUrl, includeHeaders, headerSettings, dateSettings)

    // also, release the token lock on load
    sessionStorage.setItem('tokenLock', 'false');
  }, [apiUrl])

  const handleSwitchChange = (event, newAlignment) => {
    setError({occured: false, message: ""})
    setSwitchAlignment(newAlignment);
  };

  const handleClick = async (parameters, paramaterNames) => {
    setError({occured: false, message: ""})
      await addFormulaToCell({
        params: parameters, 
        paramNames: paramaterNames,
        settings: {
          hostname: hostname,
          httpPath: httpPath,
          apiUrl: apiUrl,
          includeHeaders: includeHeaders,
          headerSettings: headerSettings
        },
      })
  };

  const handleTableCatalogQuery = async (parameters) => {
    return await getTablesAndCatalogs(apiUrl, parameters)
  }

  const handleTagSearchQuery = async (parameters) => {
    return await searchTagNames(apiUrl, parameters)
  }

  const handleSettingsChange = (hostname, httpPath, apiUrl, includeHeaders, headerSettings, dateSettings) => {
    setSettingsExcel(hostname, httpPath, apiUrl, includeHeaders, headerSettings, dateSettings).then( () => {
      setHostname(hostname)
      setHttpPath(httpPath)
      setApiUrl(apiUrl)
      setIncludeHeaders(includeHeaders)
      setHeaderSettings(headerSettings)
      setDateSettings(dateSettings)
    })
  }

  const handleFormulaEdit = (address, params) => {
    if(params["query_type"] == "sql"){
      setSwitchAlignment("Raw-SQL")
      setSQLFormData({
        outputCell: address,
        ...params
      })
    } else {
      setSwitchAlignment("Guided")
      setFormData({
        outputCell: address,
        ...params
      })
    }
  }

  const handleError = (errorMessage) => {
    setError({occured: true, message: errorMessage})
  }

  const handleWelcomeComplete = (url) => {
    setApiUrl(url)
    setSeenWelcomeScreen(true)
    setStoredAppVersionNumber(APP_VERSION_NUMBER)
    localStorage.setItem('seenWelcomeScreen', true);
    localStorage.setItem("APP_VERSION_NUMBER", APP_VERSION_NUMBER)
  }

  const handleUpdateClose = () => {
    setStoredAppVersionNumber(APP_VERSION_NUMBER)
    localStorage.setItem("APP_VERSION_NUMBER", APP_VERSION_NUMBER)
  }

  return (

    !props.isOfficeInitialized ? 
        <Progress
          title={props.title}
          logo={require("./../../../assets/rtdip-icon-300.png")}
          message="Please sideload your addin to see app body."
        />
      :      
        <div className="taskpane">

          {!seenWelcomeScreen && <WelcomeScreen handleWelcomeComplete={handleWelcomeComplete}/>}

          {(seenWelcomeScreen && storedAppVersionNumber !== APP_VERSION_NUMBER)
            && 
            <UpdateScreen handleUpdateClose={handleUpdateClose}/>
          }

          <div className="image-container">
            <img src="./../../../assets/rtdip-horizontal-color.png"></img>
          </div>

          <ToggleButtonGroup
            color="primary"
            value={switchAlignment}
            exclusive
            onChange={handleSwitchChange}
            size="small"
            sx={{ 
              marginBottom: '10px',
              display: "flex",
              justifyContent: "center"
              }}
          >
            <ToggleButton color="info" value="Guided">Auto Query</ToggleButton>
            <ToggleButton value="Raw-SQL">Raw SQL</ToggleButton>
            <ToggleButton value="Dashboard"><ViewListIcon/></ToggleButton>
          </ToggleButtonGroup>

          {switchAlignment == "Guided" && <Form handleClick={handleClick} handleTableCatalogQuery={handleTableCatalogQuery} handleTagSearchQuery={handleTagSearchQuery} loading={loading} formData={formData} handleError={handleError}/>}
          {switchAlignment == "Raw-SQL" && <RawQueryForm handleClick={(handleClick)} loading={loading} sqlFormData={sqlFormData} handleError={handleError}/>}
          {switchAlignment == "Dashboard" && <Dashboard formulaData={dashboardFormulaData} setFormulaData={setDashboardFormulaData} handleEdit={handleFormulaEdit} handleError={handleError}/>}
          
          {error.occured && 
            <Alert sx={{ marginTop: '10px' }} severity="error">
              {error.message}
            </Alert>
          }

          <SettingsDialog 
            handleSettingsChange={handleSettingsChange}
            hostname={hostname}
            httpPath={httpPath}
            apiUrl={apiUrl}
            includeHeaders={includeHeaders}
            headerSettings={headerSettings}
            dateSettings={dateSettings}
          />

        </div>
      )
}


export default App;