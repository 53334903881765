const selectCell = async (cellName) => {
    // Takes the value of the field and selects that cell in the excel sheet
    try {     
        await Excel.run(async (context) => { //NOSONAR
            const sheet = context.workbook.worksheets.getActiveWorksheet();
            const range = sheet.getRange(cellName);
            range.select();
            await context.sync();
        });
    } catch (error) {
          // console.log(error);
    }
}


const getSelectedCell = async () => {
    let selectedCell = ""
    try {
        await Excel.run(async (context) => {
          // Get the selected range.
          const range = context.workbook.getSelectedRange();
          // Load the range address.
          range.load("address");
          // Sync the loaded properties with Excel.
          await context.sync();
          // Split the range address by the colon character to get the start cell.
          selectedCell = range.address.split(":")[0];
        });
      } catch (error) {
        console.error(error);
      }

      return selectedCell
}

const getSelectedRange = async () => {
  let selectedRange = ""
  try {
      await Excel.run(async (context) => {
        // Get the selected range.
        const range = context.workbook.getSelectedRange();
        // Load the range address.
        range.load("address");
        // Sync the loaded properties with Excel.
        await context.sync();
        // Split the range address by the colon character to get the start cell.
        selectedRange = range.address
      });
    } catch (error) {
      console.error(error);
    }

    return selectedRange
}


const setSettingsExcel = async (hostname, httpPath, apiUrl, includeHeaders, headerSettings, dateSettings) => {
  try {
    localStorage.setItem('hostname', hostname);
    localStorage.setItem('httpPath', httpPath);
    localStorage.setItem('apiUrl', apiUrl);
    localStorage.setItem('includeHeaders', includeHeaders);
    localStorage.setItem('headerSettings', JSON.stringify(headerSettings));
    localStorage.setItem('dateSettings', dateSettings);

  } catch(error) {
    console.error(error);
  }

}

const getSettingsExcel = async () => {
  const output = {
    "hostname": "",
    "httpPath": "",
    "apiUrl": "",
    "includeHeaders": "",
    "headerSettings": {},
    "dateSettings": ""
  }
  try{

    output["hostname"] = localStorage.getItem("hostname");
    output["httpPath"] = localStorage.getItem("httpPath");
    output["apiUrl"] = localStorage.getItem("apiUrl");
    output["includeHeaders"] = localStorage.getItem("includeHeaders");
    output["headerSettings"] = JSON.parse(localStorage.getItem("headerSettings"));
    output["dateSettings"] = localStorage.getItem("dateSettings");
  
  } catch(error) {
    console.log("Cannot retrieve stored settings: ", error)
  }

  return output
}

function isValidExcelAddress(address) {
  try{

  // If address contains comma, then multiple cells rather than one or a range  
  if(address.includes(",")){
    return false;
  }
  
  // Regular expression to match optional sheet name and cell range
  const regex = /^(?:'?(.*?)'?!)?([A-Z]+[0-9]+)(?::([A-Z]+[0-9]+))?$/;
  const match = address.match(regex);

  if (!match) {
    return false;
  }

  const [, sheet, startCell, endCell] = match;

  // Validate the start cell address
  if (!checkExcelAddress(startCell)) {
    return false;
  }

  // If there's an end cell address, validate it too
  if (endCell && !checkExcelAddress(endCell)) {
    return false;
  }

  return true;

} catch (error) {
    return false
}
}

// Reuse the existing isValidExcelAddress function for individual cell validation
function checkExcelAddress(cellAddress) {
  const match = cellAddress.match(/^([A-Z]+)(\d+)$/);
  if (!match) {
    return false;
  }

  const [_, column, row] = match;

  let columnNumber = 0;
  for (let i = 0; i < column.length; i++) {
    columnNumber = columnNumber * 26 + (column.charCodeAt(i) - 64);
  }

  if (columnNumber < 1 || columnNumber > 16384) {
    return false;
  }

  const rowNum = parseInt(row, 10);
  if (rowNum < 1 || rowNum > 1048576) {
    return false;
  }

  return true;
}


const wrapParameterInQuotes = (parameter, parameterName) => {
  if(parameterName === "tagNames" || parameterName === "timestamps"){
    // For tagname_filter and timestamp_filter - wrap as Excel array
    const joinedVal = parameter.join('", "')
    if(joinedVal === "") return ""
    return isValidExcelAddress(joinedVal) ? parameter : `{${'"'+joinedVal+'"'}}`
  } else {
    // Normal case - wrap as Excel address or string
    return isValidExcelAddress(parameter) ? parameter : `"${parameter}"`
  }
}

const buildParameterStrings = (parameterNamesArr, parameterValuesObj) => {
  // loop over parameter names and build the parameter strings
  let output = ""
  for (let i = 0; i < parameterNamesArr.length; i++) {
    const paramName = parameterNamesArr[i]
    const paramValue = parameterValuesObj[paramName]

    // Only add if the parameter value is not null/undefined
    if (paramValue != null && paramValue !== "") {
      output += wrapParameterInQuotes(paramValue, paramName)
    }

    // Add a comma if not the last parameter
    if (i < parameterNamesArr.length - 1) {
      output += `, `
    }
  }

  return output
}


const buildFormulaString = (functionName, parameterNamesObj, parameterValuesObj) => {
  // Build the formula string from the function name and parameters

  // Create the initial part with formula name and open bracket
  let formulaString = `=RTDIP.${functionName}(`

  // Add the required parameters
  const requiredParamsArr = parameterNamesObj["required_params"]
  formulaString += buildParameterStrings(requiredParamsArr, parameterValuesObj)

  // Add the optional parameters
  const optionalParamsArr = parameterNamesObj["advanced_params"]

  // check any optional parameters are present
  const anyPresent = optionalParamsArr.some(param => parameterValuesObj[param] != null)

  if(anyPresent && optionalParamsArr.length > 0){
    formulaString += `, `
    formulaString += buildParameterStrings(optionalParamsArr, parameterValuesObj)
  }

  // Close the bracket and return the formula string
  formulaString += `)`

  return formulaString
}

const formulaStringAndColumns = (parameters={settings: {}, params: {}, paramNames: {}}) => {
  const settings = parameters["settings"]
  const params = parameters["params"]
  const paramNames = parameters["paramNames"] // obj with required_params and optional_params keys

  // Intialize the formula string and columns - start as message for error handling
  let formulaString = `An error occured while parsing inputs. Please check the inputs and try again.`
  let columns = []
  // let parsedParams = {}

  try {
    // parse parameters to wrap in either quotes or Excel addresses
    // parsedParams = buildParameterStrings(params)

    // Define the full parameter objects for the function, that adds api url as the first parameter
    const fullParams = {...params, "apiUrl": settings["apiUrl"]}
    const fullParamNames = {"required_params": ["apiUrl", ...paramNames["required_params"]], "advanced_params": paramNames["advanced_params"]}

    switch(params["query_type"]){
      case "time_weighted_average":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("TIMEWEIGHTEDAVG", fullParamNames, fullParams)
        // formulaString = `=RTDIP.TIMEWEIGHTEDAVG("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]}, ${parsedParams["start_date"]}, ${parsedParams["end_date"]}, ${parsedParams["time_interval"]}, ${parsedParams["step"]}, ${parsedParams["include_bad_data"]}, ${parsedParams["include_date_column"]}, ${parsedParams["refresh_interval"]})`
        break
      case "raw":
        columns = ["Date", "Tag", "Status", "Value"]
        formulaString = buildFormulaString("RAW", fullParamNames, fullParams)
        // formulaString = `=RTDIP.RAW("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]}, ${parsedParams["start_date"]}, ${parsedParams["end_date"]}, ${parsedParams["include_bad_data"]}, ${parsedParams["refresh_interval"]})`
        break
      case "resample":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("RESAMPLE", fullParamNames, fullParams)
        // formulaString = `=RTDIP.RESAMPLE("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]}, ${parsedParams["start_date"]}, ${parsedParams["end_date"]}, ${parsedParams["time_interval"]}, ${parsedParams["agg_method"]}, ${parsedParams["include_bad_data"]}, ${parsedParams["include_date_column"]}, ${parsedParams["refresh_interval"]})`
        break
      case "interpolate":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("INTERPOLATE", fullParamNames, fullParams)
        // formulaString = `=RTDIP.INTERPOLATE("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]}, ${parsedParams["start_date"]}, ${parsedParams["end_date"]}, ${parsedParams["time_interval"]}, ${parsedParams["agg_method"]}, ${parsedParams["interpolation_method"]}, ${parsedParams["include_bad_data"]}, ${parsedParams["include_date_column"]}, ${parsedParams["refresh_interval"]})`
        break
      case "circular_average":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("CIRCULARAVG", fullParamNames, fullParams)
        // formulaString = `=RTDIP.CIRCULARAVG("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]}, ${parsedParams["start_date"]}, ${parsedParams["end_date"]}, ${parsedParams["time_interval"]}, ${parsedParams["lower_bound"]}, ${parsedParams["upper_bound"]}, ${parsedParams["include_bad_data"]}, ${parsedParams["include_date_column"]}, ${parsedParams["refresh_interval"]})`
        break
      case  "circular_standard_deviation":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("CIRCULARSTDEV", fullParamNames, fullParams)
        // formulaString = `=RTDIP.CIRCULARSTDEV("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]}, ${parsedParams["start_date"]}, ${parsedParams["end_date"]}, ${parsedParams["time_interval"]}, ${parsedParams["lower_bound"]}, ${parsedParams["upper_bound"]}, ${parsedParams["include_bad_data"]}, ${parsedParams["include_date_column"]}, ${parsedParams["refresh_interval"]})`
        break
      case  "interpolation_at_time":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("INTERPOLATIONATTIME", fullParamNames, fullParams)
        // formulaString = `=RTDIP.INTERPOLATIONATTIME("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]}, ${parsedParams["timestamp_filter"]}, ${parsedParams["window_length"]}, ${parsedParams["include_bad_data"]}, ${parsedParams["include_date_column"]}, ${parsedParams["refresh_interval"]})`
        break
      case  "metadata":
        columns = ["TagId", "TagName", "Description",	"UoM",	"PointSource", "msrc_id"]
        formulaString = buildFormulaString("METADATA", fullParamNames, fullParams)
        // formulaString = `=RTDIP.METADATA("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]})`
        break
      case  "latest":
        columns = String(params["value_only"]).toLowerCase() ? [] : ["Date", "TagName", "Time",	"Status",	"Value"]
        formulaString = buildFormulaString("LATEST", fullParamNames, fullParams)
        // formulaString = `=RTDIP.LATEST("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]}, ${parsedParams["limit"]}, ${parsedParams["offset"]}, ${parsedParams["value_only"]}, ${parsedParams["refresh_interval"]})`
        break
      case  "summary":
          columns = []
          formulaString = buildFormulaString("SUMMARY", fullParamNames, fullParams)
          // formulaString = `=RTDIP.SUMMARY("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["data_source"]}, ${parsedParams["tagname_filter"]}, ${parsedParams["start_date"]}, ${parsedParams["end_date"]}, ${parsedParams["summary_type"]}, ${parsedParams["include_bad_data"]}, ${parsedParams["refresh_interval"]})`
          break
      case  "sql":
        columns = []
        formulaString = buildFormulaString("SQL", fullParamNames, fullParams)
        // formulaString = `=RTDIP.SQL("${settings["apiUrl"]}", ${parsedParams["region"]}, ${parsedParams["sqlQuery"]}, ${parsedParams["limit"]}, ${parsedParams["specifiedWarehouse"]}, ${parsedParams["refresh_interval"]})`
        break
      default: 
        formulaString = "An error occured while parsing inputs. Please check the inputs and try again."
    }
  } catch (err) {
    console.log(err)
  }
 
  // Include headers only if the setting is true
  columns = String(settings["includeHeaders"]) === "true" ? columns : []

  return {
    columns: columns,
    formulaString: formulaString
  }
}



const addFormulaToCell = async (parameters) => {

  const {columns, formulaString} = formulaStringAndColumns(parameters);

  const headerSettings = parameters["settings"]["headerSettings"] ? parameters["settings"]["headerSettings"] : {};
  const backgroundColor = headerSettings.backgroundColor ? headerSettings.backgroundColor : "blueviolet";
  const textColor = headerSettings.textColor ? headerSettings.textColor : "#ffffff";

  try {

    await Excel.run(async (context) => {

      // Retrieve the cell and load range properties
      const [sheetName, cellAddress] = parameters["params"]["outputCell"].split("!");
      const sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);
      const range = sheet.getRange(cellAddress);
      range.load();
      await context.sync();
      
      // Determine the start point in numeric coordinates
      const startRow = Number(range.rowIndex) + 1;
      const startCol = Number(range.columnIndex)

      // Insert table headers at original row
      if(columns.length > 0){
        const startCellAddress = numberToExcelCol(startCol) + startRow;
        const endCellAddress = numberToExcelCol(startCol + columns.length - 1) + startRow;
        const colRange = sheet.getRange(startCellAddress + ":" + endCellAddress);
        colRange.values = [columns]; 
        colRange.format.columnWidth = 90;

        // Set the background color and text color if not transparent
        if(backgroundColor !== "transparent"){
          colRange.format.fill.color = backgroundColor;
        }
        if(textColor !== "transparent"){
          colRange.format.font.color = textColor;
        }
      }

      // Insert the formula offset by 1 row if there are columns defined
      const formulaCellAddress = columns.length > 0 ? numberToExcelCol(startCol) + (startRow + 1) : numberToExcelCol(startCol) + startRow;
      const formulaRange = sheet.getRange(formulaCellAddress);
      formulaRange.formulas = [[ formulaString ]];

      await context.sync();
    }); 

  } catch (error) {
    console.log(error)
  }

}

const refreshCell = async (cell, formula) => {
  let success = false
  try {

    await Excel.run(async (context) => {

      // Retrieve the cell and load range properties
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = sheet.getRange(cell);
      range.load("formulas");
      await context.sync();

      // Check if the formula in the cell matches the formula provided
      if(range.formulas[0][0] === formula){
        // recalculate the specific cell
        range.calculate()
        await context.sync();

        // If the formula was successfully refreshed, return true
        success = true
      }
    }); 

  } catch (error) {
    console.log(error)
  }

  return success
}

const findFormulas = async () => {
  let formulasWithLocation = []
  let sheetName = ""
  try {

      await Excel.run(async (context) => {

        // load required information from excel
        const sheet = context.workbook.worksheets.getActiveWorksheet();
        const range = sheet.getUsedRange();
        range.load("formulas");
        range.load("address")
        range.load("values")
        sheet.load("name")
        await context.sync();

        // extract the sheet name
        sheetName = sheet.name

        // Extract params from range object
        const workBookContents = range.formulas
        const rangeStart = range.address;

        // Extract the starting column and row from the range
        const match = rangeStart.match(/!([A-Z]+)(\d+)/);
        const startColumn = columnToNumber(match[1]);
        const startRow = parseInt(match[2], 10);

        // Define the matching pattern for formula in cell
        const pattern = /=RTDIP/;

        // Find all formulas in the range that match the pattern
        formulasWithLocation = workBookContents.reduce((acc, subArray, subArrayIndex) => {
          // content looks like [[formula1, formula2], [formula3, formula4]] etc
          const matches = subArray
              .map((content, itemIndex) => ({
                  content,
                  value: range.values[subArrayIndex][itemIndex],
                  indices: [startRow + subArrayIndex, startColumn + itemIndex],
                  address: numberToExcelCol(startColumn + itemIndex - 1) + (startRow + subArrayIndex) 
              }))
              .filter(({ content }) => pattern.test(content));

          return acc.concat(matches);
        }, []);

      })
      return {formulas: formulasWithLocation, sheetName: sheetName}
  }
  catch (error) {
      //handle error
      console.log(error)
      return {formulas: formulasWithLocation, sheetName: sheetName}
  }
}

function addSheetIfNotPresent(input, sheetname) {
        
  if(!input.match(/^[A-Za-z0-9]+!/)){
    return sheetname + "!" + input;
  }
  // Otherwise, return unchanaged
  return input
}


function parseTopLevelItems(inputStr) {
  let result = [];
  let currentItem = '';
  let depth = 0;

  // Remove outer parentheses if they exist
  if (inputStr.startsWith('(') && inputStr.endsWith(')')) {
      inputStr = inputStr.slice(1, -1);
  }

  for (let i = 0; i < inputStr.length; i++) {
      let char = inputStr[i];

      if (char === ',' && depth === 0) {
          if (currentItem.trim() === '') {
              result.push(null);
          } else {
              result.push(currentItem.trim());
          }
          currentItem = '';
      } else {
          if (char === '{' || char === '[' || char === '(') {
              depth++;
          } else if (char === '}' || char === ']' || char === ')') {
              depth--;
          }
          currentItem += char;
      }
  }

  // Handle the last item
  if (currentItem.trim() !== '') {
      result.push(currentItem.trim());
  } else {
      result.push(null);
  }

  return result;
}


function splitFunctionString(inputStr) {
  // Remove the leading '=' if it exists
  if (inputStr.startsWith('=')) {
      inputStr = inputStr.slice(1);
  }

  // Find the index of the first dot and the first parenthesis
  const firstDotIndex = inputStr.indexOf('.');
  const firstParenIndex = inputStr.indexOf('(');

  // Extract the function name part
  const funcName = inputStr.slice(firstDotIndex + 1, firstParenIndex).trim();

  // Extract the arguments string within the brackets
  const argsStr = inputStr.slice(firstParenIndex + 1, -1); // Exclude the closing ')'

  return {
      funcName: funcName.toLowerCase(),
      argsStr: argsStr
  };
}

function parseArrayString(input) {
  // Step 1: Remove the curly braces and trim any extra whitespace
  let str = input.slice(1, -1).trim();

  let result = [];
  let currentElement = '';
  let insideQuotes = false;

  for (let char of str) {
      if (char === '"') {
          insideQuotes = !insideQuotes; // Toggle quote state
      } else if (char === ',' && !insideQuotes) {
          result.push(currentElement.trim());
          currentElement = ''; // Reset current element
      } else {
          currentElement += char; // Add char to current element
      }
  }

  // Add the last element
  if (currentElement) {
      result.push(currentElement.trim());
  }

  return result
}

function parseArgsIntoObj(args, expectedQueryParams) {
  
  const parameters = {};
  for (let i = 0; i < expectedQueryParams.length; i++) {

      // map the arguments to the query type fields
      let paramName = expectedQueryParams[i];
      let arg = args[i];

      // remove quptes around quotes
      if (arg && arg.startsWith('"') && arg.endsWith('"')) {
          // remove any double quotes
          arg = arg.slice(1, -1)
      }

      if (paramName === "tagNames" || paramName === "timestamps") {
          // If the argument is an array string, parse it into an array
          if(arg.startsWith('{') && arg.endsWith('}')){
            parameters[paramName] = parseArrayString(arg);
          } else{
            parameters[paramName] = [arg];
          }
      } else if (arg === undefined){
          parameters[paramName] = null
      }
      else {
          parameters[paramName] = arg
      }
  }

  return parameters;
}


function parseFormulaString(formula, sheetName, queryTypes, queryTypeMapping) {
  // Split the formula string into function name and arguments which are in brackets
  const { funcName, argsStr } = splitFunctionString(formula);

  // Parse the arguments into an array
  const args = parseTopLevelItems(argsStr);

  // Get the expected query arguments for the function - unpack required and optional params
  const requiredParams = queryTypes[queryTypeMapping[funcName]]["required_params"];
  const advancedParams = queryTypes[queryTypeMapping[funcName]]["advanced_params"];
  const expectedQueryArgs = [...requiredParams, ...advancedParams];

  // Parse the arguments into an object
  const parameters = parseArgsIntoObj(args, expectedQueryArgs);

  // prepare settings output
  const settings = {apiUrl: parameters["apiUrl"], sheetName: sheetName}

  // prepare parameters output
  parameters["query_type"] = queryTypeMapping[funcName]
  delete parameters["apiUrl"] // remove apiUrl from parameters

  return { params: parameters, settings: settings };
}

const clearCellContent = async (address) => {
  try {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = sheet.getRange(address);
  
      range.clear();
  
      await context.sync();
  });
  } catch (error) {
    console.log(error)
  }
 
}

// Convert a column string (e.g., 'B', 'AG') to its numerical equivalent
const columnToNumber = (str) => {
  let number = 0;
  for (let i = 0; i < str.length; i++) {
    // Initlaly check if capital letter in english alphabet
    if( /^[A-Z]$/.test( str.charAt(i) )){
      number = number * 26 + (str.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
    } else {
      break
    }
  }
  return number;
}

const numberToExcelCol = (n) => {
  // Utility function to convert a number to excel column name (i.e. 0 => A)
  let result = '';

  if(Number.isInteger(n)){
    while (n >= 0) {
        const remainder = n % 26;
        result = String.fromCharCode(65 + remainder) + result;
        n = Math.floor(n / 26) - 1;
    }
  }

  return result;

}


// Function to add click handler
const addClickHandler = async (callback) => {
  let newEventResult;
  let currentFunc;
  await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      sheet.load("name")
      await context.sync();
      
      // Store the handler in a ref so it can be removed later
      currentFunc = (event) => {
          return Excel.run(async (context) => {
              const cellAddress = sheet.name + "!" + event.address
              await callback(cellAddress)
              return context.sync();
          });
      };
      
        newEventResult = sheet.onSingleClicked.add(currentFunc);

      await context.sync();
  });

  return newEventResult;
};


async function removeClickHandler(eventResult) {
    await Excel.run(eventResult.context, async (context) => {
      eventResult.remove();
      await context.sync();
    });
}



exports.selectCell                      = selectCell;
exports.getSelectedCell                 = getSelectedCell;
exports.getSelectedRange                = getSelectedRange;
exports.setSettingsExcel                = setSettingsExcel;
exports.getSettingsExcel                = getSettingsExcel;
exports.addFormulaToCell                = addFormulaToCell;
exports.findFormulas                    = findFormulas;
exports.clearCellContent                = clearCellContent;
exports.refreshCell                     = refreshCell;
exports.formulaStringAndColumns         = formulaStringAndColumns;
exports.columnToNumber                  = columnToNumber;
exports.numberToExcelCol                = numberToExcelCol;
exports.isValidExcelAddress             = isValidExcelAddress;
exports.addClickHandler                 = addClickHandler;
exports.removeClickHandler              = removeClickHandler;
exports.parseFormulaString              = parseFormulaString;
exports.addSheetIfNotPresent            = addSheetIfNotPresent;