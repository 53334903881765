import React from 'react';
import DoneIcon from '@mui/icons-material/Done';


function SearchResults(props){

    return (
        <div className='searchresults'>
            {props.results.length > 0 ?
                props.results.map(result =>
                    <button className='searchresults-result' onClick={(e) => props.handleTagToggle(result[0])}> 
                        <div className='searchresults-result-content'>
                        {result.map( (item, idx) => {
                            if(idx == 0){
                                // Tagname
                                return <p className='searchresults-result-tagname'>{item}</p>
                            } else if(idx == 1){
                                // Description
                                return <p className='searchresults-result-description'>{item}</p>
                            } else if(idx == 2){
                                // Table name
                                return <p className='searchresults-result-tablename'>{item}</p>
                            }
                        })}
                        </div>

                        <div className='searchresults-check'>
                            {props.selectedTags.includes(result[0]) && <DoneIcon />}                            
                        </div>

                    </button>
                )
                :
                <p className="searchresults-message">{props.initResultsMessage ? props.initResultsMessage : ""}</p>
            }      
        </div>
        
        
    )
}


export default SearchResults;