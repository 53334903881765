import React, {useState} from 'react'
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';

const steps = [
  {
    label: 'Welcome to RTDIP for Excel',
    description: [
        `RTDIP (Real Time Data Ingestion Platform) is a scalable, industry-standard solution for the ingestion and processing of historical and real time process data for analytics applications, engineers, and data scientists wherever they are.`,
        `This add-in allows you to fetch realtime data from Databricks via an API based on RTDIP, by using the task pane or by typing RTDIP in a cell.`,
        `To get started with the task pane, enter the URL of the API you want to connect to:`
    ],
  }
];

export default function WelcomeScreen(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [url, setUrl] = useState("")


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = () => {
    props.handleWelcomeComplete(url)
  }

  const fontStyle = {style: {fontSize: 14}}

  return (
    <Dialog open={true} fullScreen >

        <div style={{marginTop: "50px", marginBottom: "0px"}}>
            <div className="welcome-image-container">
                <img src="./../../../assets/rtdip-horizontal-color.png"></img>
            </div>
        </div>  
    
    {/* Content */}
      <Box sx={{ p: 2, padding: "0 30px", height: "100%" }}>
        {steps[activeStep].description.map((line, index) => (
            <Typography key={index} variant="body1" style={{marginBottom: "10px"}}>{line}</Typography>
        ))}

        <TextField  
            label="Api Url"
            size="small" margin="dense"
            fullWidth={true}
            inputProps={fontStyle}
            InputLabelProps={fontStyle}
            value={url}
            onChange={(event) => setUrl(event.target.value)}
          />

        <div style={{backgroundColor: "aliceblue", marginTop: "30px", marginBottom: "20px", padding: "10px", borderRadius: "10px"}}>
          <p>Learn more about the RTDIP Add-in:</p>
          <a href="https://ssip-docs.shell.com/exceladdin/about/"><Button>View the docs</Button></a>
        </div>

      </Box>
      

      <MobileStepper
        variant="progress"
        steps={2}
        position="static"
        activeStep={url.length==0 ? 0 : 1}
        sx={{ maxWidth: 400, marginBottom: "0px"}}
        nextButton={
            activeStep === steps.length - 1 ?
                <Button size="small" onClick={handleFinish} color="info" disabled={url.length==0}>
                    Get Started
                </Button>
            :
                <Button size="small" onClick={handleNext} color="info">
                    Next<KeyboardArrowRight />
                </Button>
        }
        backButton={
    
            <Button size="small" onClick={handleBack} disabled={activeStep === 0} >
                <KeyboardArrowLeft />Back
            </Button>
        }
    />
    </Dialog>
  );
}
